<template>
	<div>
		<v-responsive
			id="home"
			class="animated-gradient---incasa animated-incasa-gradient">
			<v-responsive
				:height="`${heightVideo}`"
				class="d-flex justify-center align-center back-logo"
				style="background-image: url('/assets/images/back-home.png')">
				<v-container class="">
					<v-row justify="center">
						<v-col md="6">
							<v-img
								lazy-src="/assets/images/incasa-home.svg"
								aspect-ratio="1.4"
								contain
								src="/assets/images/incasa-home.svg"></v-img>
						</v-col>

						<!-- <v-col md="12" class="mb-12">
						<span class="text-h6 font-weight-medium text--shadow text-uppercase"
							>Asesoría</span
						>
					</v-col> -->
					</v-row>
				</v-container>
			</v-responsive>
		</v-responsive>

		<v-responsive class="dorado-alt elevation--16">
			<v-container>
				<v-row>
					<v-col cols class="py-16">
						<h2 class="display-2 text-center white--text">
							Su socio confiable en Inversiones Inmobiliarias
						</h2>
						<p class="lead text-center white--text my-4">
							Somos una empresa líder en el sector de inversiones inmobiliarias
							con una trayectoria de más de 8 años de éxito y crecimiento
							continuo. Nos especializamos en ofrecer soluciones integrales para
							inversores que buscan oportunidades lucrativas en el mercado
							inmobiliario.
						</p>
					</v-col>
				</v-row>
			</v-container>
		</v-responsive>

		<v-responsive id="quienes-somos" class="grey darken-4 elevation-14">
			<v-row class="my--16 py--16">
				<v-col md="4" class="d-flex flex-column align-end">
					<v-col md="10" class="mt-6">
						<h3 class="display-2 text-center white--text mb-6">
							Propiedades disponibles
						</h3>

						<v-autocomplete
							v-model="value"
							:items="states"
							dark
							dense
							filled
							item-text="name"
							item-value="key"
							label="Estado"></v-autocomplete>

						<v-autocomplete
							v-model="value"
							:items="propertyType"
							dark
							filled
							item-text="name"
							item-value="title"
							label="Tipo de propiedad"></v-autocomplete>

						<v-btn dark dense filled color="dorado">Buscar</v-btn>
					</v-col>

					<!--////-->
				</v-col>
				<v-col md="">
					<MapBoxLayout></MapBoxLayout>
				</v-col>
			</v-row>
		</v-responsive>

		<v-responsive
			class="animated-gradient-blue-grey d-flex align-end"
			id="porque">
			<v-container>
				<v-row class="my-12 py-12">
					<v-col md="12" class="d-flex flex-column justify-center">
						<h3
							class="display-3 font-weight-regular dorado-alt--text mb-4 text-center">
							¿Porqué elegir InCasa?
						</h3>
					</v-col>
					<v-col md="12" class="">
						<v-row class="mb-8">
							<v-col md="3" class="text-center grey darken-4 py-12">
								<v-icon size="128" class="block dorado--text"
									>mdi-account-convert</v-icon
								>
							</v-col>
							<v-col class="d-flex align-center pa-12">
								<div>
									<h3 class="text-h5 dorado-alt--text text--darken-4">
										Atención Personalizada
									</h3>
									<p class="grey--text text--darken-4 lead">
										Entendemos que cada cliente tiene necesidades y objetivos
										únicos. Por ello, ofrecemos un enfoque personalizado para
										cada proyecto, adaptándonos a las necesidades específicas de
										cada inversor.
									</p>
								</div>
							</v-col>
						</v-row>

						<v-row class="mb-8">
							<v-col md="3" class="text-center grey darken-4 py-12">
								<v-icon size="128" class="block dorado--text"
									>mdi-human-greeting-proximity</v-icon
								>
							</v-col>
							<v-col class="d-flex align-center pa-12">
								<div>
									<h3 class="text-h5 dorado-alt--text text--darken-4">
										Red de Contactos
									</h3>
									<p class="grey--text text--darken-4 lead">
										Gracias a nuestra extensa red de contactos y alianzas
										estratégicas en el mercado, podemos ofrecer acceso a las
										mejores oportunidades de inversión y a un amplio abanico de
										servicios complementarios.
									</p>
								</div>
							</v-col>
						</v-row>

						<v-row class="mb-8">
							<v-col md="3" class="text-center grey darken-4 py-12">
								<v-icon size="128" class="block dorado--text"
									>mdi-star-shooting-outline</v-icon
								>
							</v-col>
							<v-col class="d-flex align-center pa-12">
								<div>
									<h3 class="text-h5 dorado-alt--text text--darken-4">
										Experiencia
									</h3>
									<p class="grey--text text--darken-4 lead">
										Con más de 8 años en el mercado, nuestro equipo cuenta con
										una amplia experiencia y conocimientos profundos en el
										sector inmobiliario.
									</p>
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-responsive>

		<v-responsive class="d-flex align-end grey darken-4" id="contacto">
			<v-container>
				<v-row class="my-12 py-16">
					<v-col md="12" class="d-flex flex-column justify-center">
						<h3 class="display-3 dorado--text mb-4 text-center">Contactanos</h3>
						<p class="lead white--text">
							Si está buscando oportunidades de inversión inmobiliaria rentables
							y un socio confiable para guiarlo en su camino hacia el éxito,
							<strong>¡InCasa es su mejor opción!</strong> Contáctenos hoy mismo
							para conocer más sobre nuestros servicios y cómo podemos ayudarlo
							a alcanzar sus objetivos financieros.
						</p>
					</v-col>
					<v-col md="12" class="">
						<v-row class="d-flex justify-center">
							<v-col md="4" class="text-center">
								<v-icon size="96" class="dorado--text">mdi-at</v-icon>
								<h3 class="text-h5">
									<a
										href="mailto:ventas@incasasoluciones.com"
										class="blue-grey--text text--lighten-5 text-decoration-none"
										>ventas@incasasoluciones.com</a
									>
								</h3>
							</v-col>
							<v-col md="4" class="text-center">
								<v-icon size="96" class="dorado--text"
									>mdi-phone-settings</v-icon
								>
								<h3 class="text-h5">
									<a
										href="tel:015544443333"
										class="blue-grey--text text--lighten-5 text-decoration-none"
										>01 55 4444 3333</a
									>
								</h3>
							</v-col>
							<v-col md="4" class="text-center">
								<v-icon size="96" class="dorado--text">mdi-at</v-icon>
								<h3 class="text-h5">
									<a
										href="mailto:atencion@incasasoluciones.com"
										class="blue-grey--text text--lighten-5 text-decoration-none"
										>atencion@incasasoluciones.com</a
									>
								</h3>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-responsive>

		<v-responsive class="d-flex align-end" id="clientes">
			<v-container
				style="
					background: url('/assets/images/city5.png') no-repeat bottom;
					background-size: contain;
				">
				<v-row class="my-16 py-16">
					<v-col md="12" class="d-flex flex-column justify-center">
						<h3
							class="display-3 blue-grey--text text--darken-4 mb-4 text-center">
							Las opiniones de nuestros clientes
						</h3>
					</v-col>
					<v-col>
						<carousel
							cycle
							:autoplay="true"
							:perPageCustom="[
								[320, 1],
								[480, 2],
								[768, 3],
								//[1199, 4],
							]"
							:centerMode="true"
							:loop="true"
							:speed="800"
							:autoplayTimeout="3000"
							:interval="6000">
							<slide v-for="(comment, i) in comments" :key="i" :data-index="i">
								<div class="ma-4 pa-4 text-center">
									<v-avatar color="white" size="275" class="elevation-5">
										<img
											:src="comment.photo"
											:lazy-src="comment.photo"
											class="mx-auto mt-2" />
									</v-avatar>
									<h4 class="mt-4 text-h4">{{ comment.name }}</h4>
									<small class="text-uppercase blue-grey--text text--darken-2">
										{{ comment.job }}
									</small>
									<p class="blue-grey--text text--darken-3">
										{{ comment.comment }}
									</p>
								</div>
							</slide>
						</carousel>
					</v-col>
				</v-row>
			</v-container>
		</v-responsive>

		<v-footer color="grey darken-4" class="">
			<v-container>
				<v-row>
					<v-col class="text-center">
						<small
							class="blue-grey--text text--lighten-3 text--uppercase font-weight-light">
							Derechos reservados 2024 <br /><strong>INCASA</strong>, Soluciones
							Jurídicas e Inversiones Inmobiliarias
						</small>
					</v-col>
					<!-- <v-col>2</v-col>
					<v-col>3</v-col> -->
				</v-row>
			</v-container>
		</v-footer>
	</div>
</template>

<script>
import MapBoxLayout from "@/components/MapBox.vue";
import jsonComments from "@/assets/data/comments.json";
import jsonPropertyType from "@/assets/data/propertyType.json";
import jsonState from "@/assets/data/states.json";

export default {
	name: "HelloWorld",

	components: {
		MapBoxLayout,
	},

	data: () => ({
		heightVideo: "99vh",
		changeMenu: false,
		comments: jsonComments,
		propertyType: jsonPropertyType,
		states: jsonState,

		meta: {
			app_name: process.env.VUE_APP_NAME,
			app_version: process.env.VUE_APP_VERSION,
			base_url: process.env.VUE_APP_BASE_URL,
			endpoint: process.env.VUE_APP_ENDPOINT,
			storage_files: process.env.VUE_APP_STORAGE_FILES,
		},
	}),

	computed: {
		nameRoute: function () {
			return this.$route.name;
		},
		breakpoint: function () {
			return this.$vuetify.breakpoint;
		},
	},

	mounted() {},

	methods: {},
};
</script>

<style>
.VueCarousel-dot {
	background-color: #b0bec5 !important;
}
.VueCarousel-dot--active {
	background-color: #455a64 !important;
}
</style>
